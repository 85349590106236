import { IPublicClientApplication, LogLevel, PublicClientApplication } from '@azure/msal-browser';

import { ConfigurationProvider } from '../configuration/ConfigurationProvider';
import { IdentityService } from './IdentityService';
import { UrlUtility } from '../../utils/UrlUtility';

export class IdentityProvider {


    static msalInstance: IPublicClientApplication;

    static async init() {
        let msalInstance = new PublicClientApplication(this.getConfiguration());
        IdentityService.initInstance(msalInstance);
        IdentityProvider.msalInstance = msalInstance;
    }

    static getMsalClient(): IPublicClientApplication {
        return IdentityProvider.msalInstance;
    }

    static getConfiguration() {
        const identityConfig = ConfigurationProvider.getConfiguration().Identity;
        return  ({
            auth: {
                clientId: identityConfig.ClientId,
                authority: this.getB2CPolicies().authorities.signUpSignIn.authority,
                knownAuthorities: [this.getB2CPolicies().authorityDomain.toLocaleLowerCase()],
                redirectUri: ConfigurationProvider.getConfiguration().App.BaseUrl,
                navigateToLoginRequestUrl: true,
            },
            cache: {
                cacheLocation: "localStorage",
                storeAuthStateInCookie: false,
            },
            system: {
                allowRedirectInIframe: true,
                loggerOptions: {
                    logLevel: LogLevel.Verbose,
                    loggerCallback: (level:any, message:any, containsPii:any) => {

                        if (containsPii) {
                            return;
                        }

                        switch (level) {
                            case LogLevel.Error:
                                console.error(message);
                                return;
                            case LogLevel.Info:
                             //   console.info(message);
                                return;
                            case LogLevel.Verbose:
                               // console.debug(message);
                                return;
                            case LogLevel.Warning:
                                console.warn(message);
                                return;
                        }
                    }
                }
            }
        });
    }




    static getB2CPolicies() {
        const identityConfig = ConfigurationProvider.getConfiguration().Identity;


        return({
            names: {
                signUpSignIn: identityConfig.SignUpSignInPolicyId,
                forgotPassword: identityConfig.ResetPasswordPolicyId,
                editProfile: identityConfig.EditProfilePolicyId,
                signUp: identityConfig.SignUpPolicyId,
            },
            authorities: {
                signUpSignIn: {
                    authority: `${identityConfig.Instance}${identityConfig.Domain}/${identityConfig.SignUpSignInPolicyId}`,
                },
                forgotPassword: {
                    authority: `${identityConfig.Instance}${identityConfig.Domain}/${identityConfig.ResetPasswordPolicyId}`,
                },
                editProfile: {
                    authority: `${identityConfig.Instance}${identityConfig.Domain}/${identityConfig.EditProfilePolicyId}`,
                },
                signUp: {
                    authority: `${identityConfig.Instance}${identityConfig.Domain}/${identityConfig.SignUpPolicyId}`,
                },
            },
            authorityDomain: identityConfig.Authority
        });
    }


    static getBaseLoginRequest(){
        const identityConfig = ConfigurationProvider.getConfiguration().Identity;

        return ({
            scopes:identityConfig.Scopes
        });
    }


}
